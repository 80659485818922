@use 'sass:map';

@import 'font-variables';

@mixin _set-font-styles(
  $font-family,
  $font-style,
  $font-weight,
  $font-size,
  $line-height,
  $letter-spacing,
  $text-transform
) {
  font-family: $font-family;
  font-style: $font-style;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;

  letter-spacing: $letter-spacing;
  text-transform: $text-transform;
}

@mixin inherit-font-styles() {
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;

  letter-spacing: inherit;
  text-transform: inherit;
}

// headings

@mixin heading-primary-bold-32-font-styles() {
  @include _set-font-styles($font-family-primary, normal, bold, 32px, 43.58px, normal, none);
}

@mixin heading-primary-semibold-32-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 600, 32px, 43.58px, normal, none);
}

@mixin heading-primary-medium-32-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 500, 32px, 43.58px, normal, none);
}

@mixin heading-primary-regular-32-font-styles() {
  @include _set-font-styles($font-family-primary, normal, normal, 32px, 43.58px, normal, none);
}

@mixin heading-primary-bold-24-font-styles() {
  @include _set-font-styles($font-family-primary, normal, bold, 24px, 32.69px, normal, none);
}

@mixin heading-primary-semibold-24-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 600, 24px, 32.69px, normal, none);
}

@mixin heading-primary-medium-24-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 500, 24px, 32.69px, normal, none);
}

@mixin heading-primary-regular-24-font-styles() {
  @include _set-font-styles($font-family-primary, normal, normal, 24px, 32.69px, normal, none);
}

@mixin heading-primary-bold-20-font-styles() {
  @include _set-font-styles($font-family-primary, normal, bold, 20px, 27.24px, normal, none);
}

@mixin heading-primary-semibold-20-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 600, 20px, 27.24px, normal, none);
}

@mixin heading-primary-medium-20-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 500, 20px, 27.24px, normal, none);
}

@mixin heading-primary-regular-20-font-styles() {
  @include _set-font-styles($font-family-primary, normal, normal, 20px, 27.24px, normal, none);
}

@mixin heading-secondary-bold-24-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, bold, 24px, 29.71px, normal, none);
}

@mixin heading-secondary-semibold-24-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 600, 24px, 29.71px, normal, none);
}

@mixin heading-secondary-medium-24-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 500, 24px, 29.71px, normal, none);
}

@mixin heading-secondary-regular-24-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, normal, 24px, 29.71px, normal, none);
}

@mixin heading-secondary-medium-20-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 500, 20px, 27.24px, normal, none);
}

// subtitles

@mixin subtitle-primary-bold-16-font-styles() {
  @include _set-font-styles($font-family-primary, normal, bold, 16px, 21.79px, normal, none);
}

@mixin subtitle-primary-semibold-16-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 600, 16px, 21.79px, normal, none);
}

@mixin subtitle-primary-medium-16-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 500, 16px, 21.79px, normal, none);
}

@mixin subtitle-primary-regular-16-font-styles() {
  @include _set-font-styles($font-family-primary, normal, normal, 16px, 21.79px, normal, none);
}

@mixin subtitle-primary-bold-14-font-styles() {
  @include _set-font-styles($font-family-primary, normal, bold, 14px, 19.07px, normal, none);
}

@mixin subtitle-primary-semibold-14-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 600, 14px, 19.07px, normal, none);
}

@mixin subtitle-primary-medium-14-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 500, 14px, 19.07px, normal, none);
}

@mixin subtitle-primary-regular-14-font-styles() {
  @include _set-font-styles($font-family-primary, normal, normal, 14px, 19.07px, normal, none);
}

@mixin subtitle-primary-bold-12-font-styles() {
  @include _set-font-styles($font-family-primary, normal, bold, 12px, 16.34px, normal, none);
}

@mixin subtitle-primary-semibold-12-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 600, 12px, 16.34px, normal, none);
}

@mixin subtitle-primary-medium-12-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 500, 12px, 16.34px, normal, none);
}

@mixin subtitle-primary-regular-12-font-styles() {
  @include _set-font-styles($font-family-primary, normal, normal, 12px, 16.34px, normal, none);
}

@mixin subtitle-primary-bold-10-font-styles() {
  @include _set-font-styles($font-family-primary, normal, bold, 10px, 16px, normal, none);
}

@mixin subtitle-primary-semibold-10-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 600, 10px, 16px, normal, none);
}

@mixin subtitle-primary-medium-10-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 500, 10px, 16px, normal, none);
}

@mixin subtitle-primary-regular-10-font-styles() {
  @include _set-font-styles($font-family-primary, normal, normal, 10px, 16px, normal, none);
}

@mixin subtitle-secondary-bold-16-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, bold, 16px, 21.79px, normal, none);
}

@mixin subtitle-secondary-semibold-16-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 600, 16px, 21.79px, normal, none);
}

@mixin subtitle-secondary-medium-16-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 500, 16px, 21.79px, normal, none);
}

@mixin subtitle-secondary-regular-16-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, normal, 16px, 21.79px, normal, none);
}

@mixin subtitle-secondary-bold-14-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, bold, 14px, 19.07px, normal, none);
}

@mixin subtitle-secondary-semibold-14-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 600, 14px, 19.07px, normal, none);
}

@mixin subtitle-secondary-medium-14-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 500, 14px, 19.07px, normal, none);
}

@mixin subtitle-secondary-regular-14-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, normal, 14px, 19.07px, normal, none);
}

@mixin subtitle-secondary-bold-12-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, bold, 12px, 16.34px, normal, none);
}

@mixin subtitle-secondary-semibold-12-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 600, 12px, 16.34px, normal, none);
}

@mixin subtitle-secondary-medium-12-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 500, 12px, 16.34px, normal, none);
}

@mixin subtitle-secondary-regular-12-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, normal, 12px, 16.34px, normal, none);
}

@mixin subtitle-secondary-bold-10-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, bold, 10px, 16px, normal, none);
}

@mixin subtitle-secondary-semibold-10-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 600, 10px, 16px, normal, none);
}

@mixin subtitle-secondary-medium-10-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, 500, 10px, 16px, normal, none);
}

@mixin subtitle-secondary-regular-10-font-styles() {
  @include _set-font-styles($font-family-secondary, normal, normal, 10px, 16px, normal, none);
}

// buttons
@mixin button-text-font-styles() {
  @include _set-font-styles($font-family-primary, normal, 600, 14px, 19.07px, normal, none);
}

// helpers

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  //white-space: nowrap;
}

@mixin first-letter-up() {
  &::first-letter {
    text-transform: uppercase !important;
  }
}
