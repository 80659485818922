@import 'typography/mixins';
@import 'theming/functions';
@import 'core/screen-sizes';

$bg-main-color: app-color(bg-main-color);

.app-dialog-notification {
  &__wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.4);

    @include xxs_screen {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__window {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 24px 24px 0 24px;
    border-radius: 12px;
    background-color: $bg-main-color;
    z-index: 1000;

    @include xxs_screen {
      margin: 0 16px;
    }
  }

  &__close {
    position: absolute;
    right: 30px;
    margin-top: -40px;
    margin-right: -25px;
    border-radius: 2px;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
    //padding-left: 24px;
  }

  &__title {
    width: 100%;
    //margin-top: 10px;
    text-align: center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
