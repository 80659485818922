@import 'theming/functions';

$bg-main-color: app-color(bg-main-color);
$bg-additional-color: app-color(bg-additional-color);
$bg-extra-color: app-color(bg-extra-color);
$bg-extra-2-color: app-color(bg-extra-2-color);
$bg-block-main-color: app-color(bg-block-main-color);
$bg-light-blue-addit: app-color(bg-light-blue-addit);
$bg-light-blue-addit-2: app-color(bg-light-blue-addit-2);
$bg-light-blue-addit-3: app-color(bg-light-blue-addit-3);
$bg-dark-color: app-color(bg-dark-color);
$bg-dark-color-50: app-color(bg-dark-color, 0.5);
$bg-dark-color-25: app-color(bg-dark-color, 0.25);
$success-color: app-color(success-color);
$success-blue-color: app-color(success-blue-color);
$success-blue-color-5: app-color(success-blue-color, 0.05);
$danger-color: app-color(danger-color);
$danger-color-5: app-color(danger-color, 0.05);
$warning-color: app-color(warning-color);

.app-bg-main {
  background-color: $bg-main-color;
}

.app-bg-extra {
  background-color: $bg-extra-color;
}

.app-bg-extra-2 {
  background-color: $bg-extra-2-color;
}

.app-bg-addit {
  background-color: $bg-additional-color;
}

.app-bg-light-blue-addit {
  background-color: $bg-light-blue-addit;
}

.app-bg-light-blue-addit-2 {
  background-color: $bg-light-blue-addit-2;
}

.app-bg-light-blue-addit-3 {
  background-color: $bg-light-blue-addit-3;
}

.app-bg-block-main {
  background-color: $bg-block-main-color;
}

.app-no-bg {
  background-color: transparent !important;
}

.app-bg-success {
  background-color: $success-color;
}

.app-bg-success-blue {
  background-color: $success-blue-color;

  &-5 {
    background-color: $success-blue-color-5;
  }
}

.app-bg-danger {
  background-color: $danger-color;

  &-5 {
    background-color: $danger-color-5;
  }
}

.app-bg-warning {
  background-color: $warning-color;
}

.app-bg-addit-i {
  background-color: $bg-additional-color !important;
}

.app-bg-dark {
  background-color: $bg-dark-color;

  &-50 {
    background-color: $bg-dark-color-50;
  }

  &-25 {
    background-color: $bg-dark-color-25;
  }
}

.app-bg-red {
  background-color: $red;
}

.app-bg-blue {
  background-color: $blue;
}

.app-bg-green {
  background-color: $green;
}

.app-bg-purple {
  background-color: $purple;
}
