@import 'theming/functions';

$bg-color: app-color(bg-main-color);
$success-color: app-color(success-color);
$danger-color: app-color(danger-color);
$warning-color: app-color(warning-color);

.app-empty-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 200px;
  pointer-events: none;
  color: $bg-color;

  &.--pointer-events-enabled {
    pointer-events: all;
  }

  &.--danger {
    color: $danger-color;
  }

  &.--success {
    color: $success-color;
  }

  &.--warning {
    color: $warning-color;
  }
}

.app-loader-skeleton {
  position: relative;
  overflow: hidden;
}

.app-loader-skeleton::before {
  content: '';
  display: block;
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #f4f6ff 50%, transparent 100%);
  animation: load 1.3s ease infinite;
}

@keyframes load {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
}
