.--flex {
  display: flex;
}

.--row {
  flex-direction: row;
}

.--col {
  flex-direction: column;
}

.--jc-center {
  justify-content: center;
}

.--jc-space {
  justify-content: space-between;

  &-i {
    justify-content: space-between !important;
  }
}

.--jc-around {
  justify-content: space-around;

  &-i {
    justify-content: space-around !important;
  }
}

.--jc-start {
  justify-content: flex-start;
}

.--jc-end {
  justify-content: flex-end;
}

.--align-center {
  align-items: center;
}

.--align-content-center {
  align-content: center;
}

.--align-start {
  align-items: flex-start;
}

.--align-end {
  align-items: flex-end;

  &-i {
    align-items: flex-end !important;
  }
}

.--wrap {
  flex-wrap: wrap;
}

@for $count from 0 through 300 {
  .--gap-#{$count} {
    gap: 1px * $count;
  }
}

.--pos-fix {
  position: fixed !important;
}

.--pos-abs {
  position: absolute;
}

.--pos-rel {
  position: relative;
}

.--pos-sticky {
  position: sticky;
}

.--pos-inherit {
  position: inherit;
}

.--overflow-auto {
  overflow: auto !important;
}

.--overflow-hidden {
  overflow: hidden !important;
}

.--overflow-visible {
  overflow: visible !important;
}

@for $count from 0 through 2 {
  .fl-#{$count} {
    flex: 1 * $count;
  }
}
