.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.m-center {
  margin: 0 auto;
}

.w-50 {
  width: 50%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-100 {
  width: 100%;
}

.w-50-i {
  width: 50% !important;
}

.w-30-i {
  width: 30% !important;
}

.w-100-i {
  width: 100% !important;
}

.h-100 {
  height: 100%;

  &-i {
    height: 100% !important;
  }
}

.h-auto {
  height: auto;

  &-i {
    height: auto !important;
  }
}

@for $count from 0 through 1200 {
  .mrgn-#{$count} {
    margin: 1px * $count;

    &-i {
      margin: 1px * $count !important;
    }
  }

  .mrgn-x-#{$count} {
    margin-left: 1px * $count;
    margin-right: 1px * $count;

    &-i {
      margin-left: 1px * $count !important;
      margin-right: 1px * $count !important;
    }
  }

  .mrgn-y-#{$count} {
    margin-top: 1px * $count;
    margin-bottom: 1px * $count;

    &-i {
      margin-top: 1px * $count !important;
      margin-bottom: 1px * $count !important;
    }
  }

  .mrgn-top-#{$count} {
    margin-top: 1px * $count;

    &-i {
      margin-top: 1px * $count !important;
    }
  }

  .mrgn-btm-#{$count} {
    margin-bottom: 1px * $count;

    &-i {
      margin-bottom: 1px * $count !important;
    }
  }

  .mrgn-lft-#{$count} {
    margin-left: 1px * $count;

    &-i {
      margin-left: 1px * $count !important;
    }
  }

  .mrgn-rgt-#{$count} {
    margin-right: 1px * $count;

    &-i {
      margin-right: 1px * $count !important;
    }
  }

  .mrgn-top--#{$count} {
    margin-top: -1px * $count;
  }

  .mrgn-btm--#{$count} {
    margin-bottom: -1px * $count;
  }

  .mrgn-lft--#{$count} {
    margin-left: -1px * $count;
  }

  .mrgn-rgt--#{$count} {
    margin-right: -1px * $count;
  }
}

@for $count from 0 through 150 {
  .gap-#{$count} {
    gap: 1px * $count;
  }
}

@for $count from 0 through 100 {
  .wi-#{$count} {
    width: 1% * $count;
  }

  .wi-#{$count}-i {
    width: 1% * $count !important;
  }

  .w-#{$count}-vw {
    width: 1vw * $count;
  }

  .h-#{$count}-vh {
    height: 1vh * $count;
  }
}

@for $count from 0 through 1400 {
  .w-#{$count}-px {
    width: 1px * $count;

    &-i {
      width: 1px * $count !important;
    }
  }

  .max-w-#{$count}-px {
    max-width: 1px * $count;

    &-i {
      max-width: 1px * $count !important;
    }
  }

  .min-w-#{$count}-px {
    min-width: 1px * $count;

    &-i {
      min-width: 1px * $count !important;
    }
  }

  .max-w-#{count} {
    max-width: 1% * $count;

    &-i {
      max-width: 1% * $count !important;
    }
  }

  .min-w-#{count} {
    min-width: 1% * $count;

    &-i {
      min-width: 1% * $count !important;
    }
  }

  .h-#{$count}-px {
    height: 1px * $count;

    &-i {
      height: 1px * $count !important;
    }
  }

  .max-h-#{$count}-px {
    max-height: 1px * $count;

    &-i {
      max-height: 1px * $count !important;
    }
  }

  .min-h-#{$count} {
    min-height: 1% * $count;

    &-i {
      min-height: 1% * $count !important;
    }
  }

  .min-h-#{$count}-px {
    min-height: 1px * $count;

    &-i {
      min-height: 1px * $count !important;
    }
  }

  .max-h-#{count} {
    max-height: 1% * $count;

    &-i {
      max-height: 1% * $count !important;
    }
  }

  .min-h-#{count} {
    min-height: 1% * $count;

    &-i {
      min-height: 1% * $count !important;
    }
  }
}

@for $count from 0 through 150 {
  .pdng-#{$count} {
    padding: 1px * $count;

    &-i {
      padding: 1px * $count !important;
    }
  }

  .pdng-top-#{$count} {
    padding-top: 1px * $count;

    &-i {
      padding-top: 1px * $count !important;
    }
  }

  .pdng-btm-#{$count} {
    padding-bottom: 1px * $count;

    &-i {
      padding-bottom: 1px * $count !important;
    }
  }

  .pdng-lft-#{$count} {
    padding-left: 1px * $count;

    &-i {
      padding-left: 1px * $count !important;
    }
  }

  .pdng-rgt-#{$count} {
    padding-right: 1px * $count;

    &-i {
      padding-right: 1px * $count !important;
    }
  }

  .pdng-y-#{$count} {
    padding-top: 1px * $count;
    padding-bottom: 1px * $count;

    &-i {
      padding-top: 1px * $count !important;
      padding-bottom: 1px * $count !important;
    }
  }

  .pdng-x-#{$count} {
    padding-left: 1px * $count;
    padding-right: 1px * $count;

    &-i {
      padding-left: 1px * $count !important;
      padding-right: 1px * $count !important;
    }
  }
}
