@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../../assets/fonts/Noto_Sans/regular/notosans-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/fonts/Noto_Sans/regular/notosans-regular.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
  url('../../assets/fonts/Noto_Sans/regular/notosans-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/Noto_Sans/medium/notosans-medium.woff2') format('woff2'),
  url('../../assets/fonts/Noto_Sans/medium/notosans-medium.woff') format('woff'),
  url('../../assets/fonts/Noto_Sans/medium/notosans-medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../assets/fonts/Noto_Sans/semibold/notosans-display-semibold.woff2') format('woff2'),
  url('../../assets/fonts/Noto_Sans/semibold/notosans-display-semibold.woff') format('woff'),
  url('../../assets/fonts/Noto_Sans/semibold/notosans-display-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../../assets/fonts/Noto_Sans/bold/notosans-bold.woff2') format('woff2'),
  url('../../assets/fonts/Noto_Sans/bold/notosans-bold.woff') format('woff'),
  url('../../assets/fonts/Noto_Sans/bold/notosans-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('../../assets/fonts/Gilroy/regular/gilroy-regular.woff2') format('woff2'),
  url('../../assets/fonts/Gilroy/regular/gilroy-regular.woff') format('woff'),
  url('../../assets/fonts/Gilroy/regular/gilroy-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../assets/fonts/Gilroy/semibold/gilroy-semibold.woff2') format('woff2'),
  url('../../assets/fonts/Gilroy/semibold/gilroy-semibold.woff') format('woff'),
  url('../../assets/fonts/Gilroy/semibold/gilroy-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url('../../assets/fonts/Gilroy/bold/gilroy-bold.woff2') format('woff2'),
  url('../../assets/fonts/Gilroy/bold/gilroy-bold.woff') format('woff'),
  url('../../assets/fonts/Gilroy/bold/gilroy-bold.ttf') format('truetype');
}
