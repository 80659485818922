@import 'typography/mixins';
@import 'theming/functions';
@import 'core/screen-sizes';

$bg-light-blue-addit: app-color(bg-light-blue-addit);
$text-color: app-color(text-color);
$box-shadow: app-palette-val(box-shadow);

$tail-width: 10px;

.cdk-overlay-container {
  .mdc-tooltip {
    min-height: 26px;
    border-radius: 6px;
    padding: 6px 12px;

    word-break: break-word;
    text-transform: none;
    white-space: pre-line;

    background-color: $bg-light-blue-addit !important;
    color: $text-color;
    box-shadow: $box-shadow;

    @include subtitle-primary-regular-12-font-styles();

    &__surface {
      max-height: 100vh;
      text-align: start;
    }
  }
}

.app-tooltip-panel {
  border-radius: 4px;
  box-shadow: $box-shadow;
  background-color: $bg-light-blue-addit;

  @include xxs_screen {
    .app-tooltip {
      max-height: 300px;
      overflow: auto;
    }
  }
}

.cdk-describedby-message-container {
  position: fixed;
}

.tooltip-tail {
  display: flex;
  position: absolute;
  width: 0;
  height: 0;
  border-width: $tail-width;
  border-color: transparent $bg-light-blue-addit transparent transparent;
  z-index: 9;
  border-style: solid;
}
