@import 'theming/functions';

$bg-block-main-color: app-color(bg-block-main-color);

.mat-mdc-radio-button {
  .mdc-form-field {
    align-items: flex-start;
    border-radius: 8px;
    padding: 12px;

    .mdc-radio {
      padding: 0;
    }

    .mdc-label {
      padding-left: 20px;
    }
  }

  &.mat-mdc-radio-checked {
    .mdc-form-field {
      background-color: $bg-block-main-color;
    }
  }

  &.--mobile {
    .mdc-label {
      padding-left: 16px;
    }

    .mdc-radio__outer-circle {
      width: 16px;
      height: 16px;
    }

    .mdc-radio__inner-circle {
      width: 2px;
      height: 2px;
      border-width: 8px;
    }
  }
}
