@import 'core/variables';
@import 'theming/functions';

$bg-main-color: app-color(bg-main-color);
$text-color: app-color(text-color);
$border-color: app-color(border-color);
$box-shadow: app-palette-val(box-shadow);

.app-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.75);
}

.app-dialog-no-backdrop {
  background-color: transparent !important;
}

.cdk-overlay-pane {
  max-width: 100vw !important;

  .mat-mdc-dialog-container {
    display: flex !important;
    flex-direction: column;

    color: $text-color;
    //background-color: $bg-main-color;
    box-shadow: $box-shadow;

    border-radius: 6px !important;

    .mdc-dialog {
      &__container {
        width: 100%;
      }

      &__surface {
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
      }
    }

    // set scrollbar styles
    &::-webkit-scrollbar {
      width: $scrollbar-size;
      height: $scrollbar-size;
    }

    // set scrollbar styles
    &::-webkit-scrollbar-thumb {
      background-color: $border-color;
      border-radius: 2px;
    }
  }
}

.mat-dialog-content {
  padding: 0 25px !important;

  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $border-color;
  }
}

.app-right-side-dialog {
  .mat-dialog-container {
    border: none !important;
    border-radius: 0 !important;

    padding: 0;
  }
}

.app-dialog-no-padding {
  .mat-dialog-container {
    padding: 0;
  }
}

.app-dialog-popup {
  .mat-dialog-container {
    padding: 0;
    border: none !important;
    border-radius: 0 !important;
  }

  &.--no-bg {
    .mat-dialog-container {
      background-color: transparent;
    }
  }
}

.app-custom-datepicker-panel {
  border: 1px solid;
  border-radius: 4px;

  color: $text-color;
  border-color: $border-color;
  background-color: $bg-main-color;
  box-shadow: $box-shadow;
}
