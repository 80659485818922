@import 'theming/functions';

.app-sketch-movement,
.app-sketch-wrap-frame {
  // position: fixed;
  position: absolute;
  z-index: 999;
  cursor: move;
}

.app-sketch-resize-point,
.app-sketch-wrap-point {
  position: absolute;
  z-index: 999;
  width: 20px;
  height: 20px;
}

.app-sketch-resize-point {
  &[side='top-left'] {
    left: -10px;
    top: -10px;
    cursor: nw-resize;
  }

  &[side='top-right'] {
    right: -10px;
    top: -10px;
    cursor: ne-resize;
  }

  &[side='bottom-left'] {
    left: -10px;
    bottom: -10px;
    cursor: sw-resize;
  }

  &[side='bottom-right'] {
    right: -10px;
    bottom: -10px;
    cursor: se-resize;
  }
}

.app-sketch-wrap-point {
  cursor: pointer;

  // &[side="top-left"] {
  //   left: -10px;
  //   top: -10px;
  // }

  // &[side="top-right"] {
  //   right: -10px;
  //   top: -10px;
  // }

  // &[side="bottom-left"] {
  //   left: -10px;
  //   bottom: -10px;
  // }

  // &[side="bottom-right"] {
  //   right: -10px;
  //   bottom: -10px;
  // }

  &[side='center'] {
    // left: calc(50% - 10px);
    // top: calc(50% - 10px);
    border-radius: 50%;
  }
}

.app-sketch-rotation-point {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -20px;
  left: calc(50% - 10px);
  border-radius: 50%;
  cursor: pointer;
}

.app-sketch-rotation-point::after,
.app-sketch-rotation-point::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  transform: rotate(-45deg);
}

.app-sketch-rotation-point::after {
  border-right: 6px solid;
  top: -1px;
  left: -3px;
}

.app-sketch-rotation-point::before {
  border-left: 6px solid;
  bottom: -1px;
  right: -3px;
}
.--crsr {
  cursor: default;

  &-i {
    cursor: default !important;
  }

  &-pntr {
    cursor: pointer;
  }
}
