$common-gap: 32px;
$nav-panel-height: 76px;
$top-panel-height-mobile: 48px;
$left-panel-width: 292px;
$scrollbar-size: 8px;
$input-height: 42px;
$input-small-height: 32px;
$input-value-container-height: 40px;
$input-textarea-min-height: 80px;
$expansion-panel-horizontal-padding: 24px;
$toast-width: 600px;
$toast-mobile-width: 300px;
$footer-padding: 20px 54px;
$footer-mobile-padding: 16px;
$tooltip-max-width: 266px;
