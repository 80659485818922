@import 'core/variables';
@import 'theming/functions';

$border-color: app-color(border-color);

.app-scrollbar-block {
  // set scrollbar styles
  &::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: $border-color;
  }
}
