// @import './google-fonts';
@import 'custom-fonts';
@import 'mixins';

// base font styles

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-size: 14px;
  font-family: $font-family-primary !important;
}

input {
  &::shadow {
    div {
      font-family: $font-family-primary !important;
    }
  }
}

h1,
h2,
h3,
h4 {
  margin: 0; // не менять, рушит верстку
}

// heads

.heading-primary-bold-24 {
  @include heading-primary-bold-24-font-styles();
}

.heading-primary-semibold-24 {
  @include heading-primary-semibold-24-font-styles();
}

.heading-primary-medium-24 {
  @include heading-primary-medium-24-font-styles();
}

.heading-primary-regular-24 {
  @include heading-primary-regular-24-font-styles();
}

.heading-primary-bold-20 {
  @include heading-primary-bold-20-font-styles();
}

.heading-primary-semibold-20 {
  @include heading-primary-semibold-20-font-styles();
}

.heading-primary-medium-20 {
  @include heading-primary-medium-20-font-styles();
}

.heading-primary-regular-20 {
  @include heading-primary-regular-20-font-styles();
}

.heading-secondary-bold-24 {
  @include heading-secondary-bold-24-font-styles();
}

.heading-secondary-semibold-24 {
  @include heading-secondary-semibold-24-font-styles();
}

.heading-secondary-medium-24 {
  @include heading-secondary-medium-24-font-styles();
}

.heading-secondary-regular-24 {
  @include heading-secondary-regular-24-font-styles();
}

.heading-secondary-medium-20 {
  @include heading-secondary-medium-20-font-styles();
}

//.heading-0 {
//  @include heading-0-font-styles();
//}
//
//h1,
//.heading-1 {
//  @include heading-1-font-styles();
//}
//
//h2,
//.heading-2 {
//  @include heading-2-font-styles();
//}
//
//h3,
//.heading-3 {
//  @include heading-3-font-styles();
//}
//
//h4,
//.heading-4 {
//  @include heading-4-font-styles();
//}
//
//.heading-1-special {
//  @include heading-1-special-font-styles();
//}
//
//.heading-2-special {
//  @include heading-2-special-font-styles();
//}
//
//.heading-2-special-2 {
//  @include heading-2-special-font-styles();
//}
//
//.heading-2-special-3 {
//  @include heading-2-special-3-font-styles();
//}
//
//.heading-3-special {
//  @include heading-3-special-font-styles();
//}
//
//.heading-3-special-2 {
//  @include heading-3-special-2-font-styles();
//}
//
//.heading-3-special-3 {
//  @include heading-3-special-3-font-styles();
//}
//
//.heading-4-special {
//  @include heading-4-special-font-styles();
//}
//
//.heading-5 {
//  @include heading-5-font-styles();
//}

// subtitles

.subtitle-primary-bold-16 {
  @include subtitle-primary-bold-16-font-styles();
}

.subtitle-primary-semibold-16 {
  @include subtitle-primary-semibold-16-font-styles();
}

.subtitle-primary-medium-16 {
  @include subtitle-primary-medium-16-font-styles();
}

.subtitle-primary-regular-16 {
  @include subtitle-primary-regular-16-font-styles();
}

.subtitle-primary-bold-14 {
  @include subtitle-primary-bold-14-font-styles();
}

.subtitle-primary-semibold-14 {
  @include subtitle-primary-semibold-14-font-styles();
}

.subtitle-primary-medium-14 {
  @include subtitle-primary-medium-14-font-styles();
}

.subtitle-primary-regular-14 {
  @include subtitle-primary-regular-14-font-styles();
}

.subtitle-primary-bold-12 {
  @include subtitle-primary-bold-12-font-styles();
}

.subtitle-primary-semibold-12 {
  @include subtitle-primary-semibold-12-font-styles();
}

.subtitle-primary-medium-12 {
  @include subtitle-primary-medium-12-font-styles();
}

.subtitle-primary-regular-12 {
  @include subtitle-primary-regular-12-font-styles();
}

.subtitle-primary-bold-10 {
  @include subtitle-primary-bold-10-font-styles();
}

.subtitle-primary-semibold-10 {
  @include subtitle-primary-semibold-10-font-styles();
}

.subtitle-primary-medium-10 {
  @include subtitle-primary-medium-10-font-styles();
}

.subtitle-primary-regular-10 {
  @include subtitle-primary-regular-10-font-styles();
}

.subtitle-secondary-bold-16 {
  @include subtitle-secondary-bold-16-font-styles();
}

.subtitle-secondary-semibold-16 {
  @include subtitle-secondary-semibold-16-font-styles();
}

.subtitle-secondary-medium-16 {
  @include subtitle-secondary-medium-16-font-styles();
}

.subtitle-secondary-regular-16 {
  @include subtitle-secondary-regular-16-font-styles();
}

.subtitle-secondary-bold-14 {
  @include subtitle-secondary-bold-14-font-styles();
}

.subtitle-secondary-semibold-14 {
  @include subtitle-secondary-semibold-14-font-styles();
}

.subtitle-secondary-medium-14 {
  @include subtitle-secondary-medium-14-font-styles();
}

.subtitle-secondary-regular-14 {
  @include subtitle-secondary-regular-14-font-styles();
}

.subtitle-secondary-bold-12 {
  @include subtitle-secondary-bold-12-font-styles();
}

.subtitle-secondary-semibold-12 {
  @include subtitle-secondary-semibold-12-font-styles();
}

.subtitle-secondary-medium-12 {
  @include subtitle-secondary-medium-12-font-styles();
}

.subtitle-secondary-regular-12 {
  @include subtitle-secondary-regular-12-font-styles();
}

.subtitle-secondary-bold-10 {
  @include subtitle-secondary-bold-10-font-styles();
}

.subtitle-secondary-semibold-10 {
  @include subtitle-secondary-semibold-10-font-styles();
}

.subtitle-secondary-medium-10 {
  @include subtitle-secondary-medium-10-font-styles();
}

.subtitle-secondary-regular-10 {
  @include subtitle-secondary-regular-10-font-styles();
}

//.subtitle-1 {
//  @include subtitle-1-font-styles();
//}
//
//.subtitle-2 {
//  @include subtitle-2-font-styles();
//}
//
//.subtitle-3 {
//  @include subtitle-3-font-styles();
//}
//
//.subtitle-4 {
//  @include subtitle-4-font-styles();
//}
//
//.subtitle-1-special {
//  @include subtitle-1-special-font-styles();
//}
//
//.subtitle-1-special-2 {
//  @include subtitle-1-special-2-font-styles();
//}
//
//.subtitle-2-special {
//  @include subtitle-2-special-font-styles();
//}
//
//.subtitle-2-special-2 {
//  @include subtitle-2-special-2-font-styles();
//}
//
//.subtitle-2-special-3 {
//  @include subtitle-2-special-3-font-styles();
//}
//
//.subtitle-3-special {
//  @include subtitle-3-special-font-styles();
//}
//
//.subtitle-4-special {
//  @include subtitle-4-special-font-styles();
//}

.app-text-link {
  cursor: pointer;
  text-decoration: underline;

  &--not-underline {
    text-decoration: none;
  }

  &--disabled {
    cursor: default;
    text-decoration: none;

    pointer-events: none;
  }
}

.app-text-truncate {
  @include text-truncate();
}

.app-text-capitalize {
  text-transform: capitalize;
}

.app-text-uppercase {
  text-transform: uppercase;
}

.app-text-lower {
  text-transform: lowercase;
}

.app-text-not-capitalize {
  text-transform: none;
}

.app-text-white-space-pre {
  white-space: pre;
}

.app-text-white-space-wrap {
  white-space: pre-wrap;
}

.app-text-white-space-nowrap {
  white-space: nowrap;
}

.app-text-first-letter-up {
  @include first-letter-up();
}

.app-text-center {
  text-align: center;
}
