@import 'typography/mixins';
@import 'core/variables';
@import 'theming/colors/colors';
@import 'theming/functions';

$bg-color: app-color(bg-main-color);
$text-extra-color: app-color(text-extra-color);
$bg-extra-color: app-color(bg-extra-color);
$text-color: app-color(text-color);
$text-color-75: app-color(text-color, 0.75);
$slide-toggle-color: $light-primary;
$border-color: app-color(border-color);
$border-color-50: app-color(border-color, 0.5);

.mat-mdc-slide-toggle {
  &.--mobile {
    width: 100%;

    .mdc-form-field {
      width: 100%;
      justify-content: space-between;

      .mdc-label {
        margin-left: 0;
      }
    }
  }

  .mdc-switch__track {
    height: 20px !important;
    border: 1px solid $border-color;
    border-radius: 20px !important;
  }

  .mdc-switch__handle {
    width: 20px;
    height: 20px;
    background-color: $slide-toggle-color !important;

    &::after {
      border: 1px solid $text-extra-color;
      background: $text-extra-color !important;
    }

    .mdc-switch__icons {
      display: none;
    }
  }

  &.mat-mdc-slide-toggle-checked {
    .mdc-switch__track {
      background: $bg-extra-color !important;
    }
    .mdc-switch__handle {
      &::after {
        background: $slide-toggle-color !important;
        border: 1px solid $bg-extra-color;
      }
    }
  }
}
