@import 'core/variables';
@import 'typography/mixins';
@import 'theming/functions';

$text-color: app-color(text-color);
$text-additional-color: app-color(text-additional-color);
$text-additional-2-color: app-color(text-additional-2-color);
$border-color: app-color(border-color);
$field-focused-color: app-color(field-focused-color);
$success-blue-color: app-color(success-blue-color);
$danger-color: app-color(danger-color);

.mat-mdc-form-field:not(.app-input-form-field) {
  &.--outline-transparent {
    .mat-form-field-outline-start,
    .mat-form-field-outline-end {
      background-color: transparent !important;
    }
  }
}

.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-icon-prefix {
  display: flex;
  margin-top: -3px;
  height: 100%;
}

// appearance="outline"
.mat-mdc-form-field {
  width: 100%;
  //border: 1px solid $border-color;
  border-radius: 8px;

  &.mat-form-field-appearance-outline {
    --mdc-outlined-text-field-outline-color: #{$border-color};
    --mdc-outlined-text-field-hover-outline-color: #{$border-color};
    --mdc-outlined-text-field-focus-outline-color: #{$field-focused-color};

    height: $input-small-height;

    .mat-mdc-form-field-infix {
      min-height: unset;
      padding: 0 !important;
    }

    .mat-mdc-text-field-wrapper {
      padding: 4px 10px 4px 10px !important;
    }

    .mat-mdc-input-element {
      @include subtitle-primary-regular-14-font-styles();

      text-transform: none !important;

      color: $text-color !important;
      caret-color: $text-color !important;

      &::placeholder {
        color: $text-additional-2-color !important;
      }
    }

    &.mat-form-field-disabled .mat-mdc-input-element {
      color: $text-additional-2-color;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  &.ng-touched {
    &.ng-invalid {
      border: 1px solid;
      border-color: $danger-color;
    }
  }
}
