@import 'variables';

* {
  box-sizing: border-box;

  &:active,
  &:hover,
  &:focus {
    outline: 0;
  }
}

html {
  position: relative;
}

body {
  position: relative;
  height: 100%;
  padding: 0;
  margin: 0;

  overflow: auto;
}

.app-scroll-strategy {
  overflow: hidden;
  width: calc(100% - $scrollbar-size);
}

@for $count from 0 through 300 {
  .vw-#{$count} {
    width: 1px * $count;
  }

  .vh-#{$count} {
    height: 1px * $count;
  }
}

@for $count from 0 through 300 {
  .min-vw-#{$count} {
    min-width: 1px * $count;
  }

  .min-vh-#{$count} {
    min-height: 1px * $count;
  }
}

@for $count from 0 through 300 {
  .max-vw-#{$count} {
    max-width: 1px * $count;
  }

  .max-vh-#{$count} {
    max-height: 1px * $count;
  }
}
